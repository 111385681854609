<template>
  <ui-item
    class="ui-input-checkbox ui-clickable ui-noselect"
    :class="{'--checked': !!innerValue, '--unchecked': !innerValue}"
    :text="label"
    :icon="innerValue ? 'mdi:checkbox-marked' : 'mdi:checkbox-blank-outline'"
    icon-color="var(--ui-color-primary)"
    @click="toggle"
  ></ui-item>
</template>

<script>
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'ui-input-checkbox',
  components: { UiItem },

  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      innerValue: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = !!newValue;
      },
    },
  },

  methods: {
    toggle() {
      this.innerValue = !this.innerValue;
      this.$emit('input', this.innerValue);
    },
  },
};
</script>